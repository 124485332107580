
























































































































































































import { SfButton, SfBadge, SfIcon, SfLink, SfOverlay } from '~/components';
import HeaderNavigation from '~/components/Header/Navigation/HeaderNavigation.vue';
import {
  computed,
  ref,
  defineComponent,
  useContext,
  onMounted,
  useFetch,
  useRouter
} from '@nuxtjs/composition-api';
import { useCategory } from '~/modules/catalog/category/composables/useCategory';
import {
  useUiHelpers,
  useUiState,
  useWebsiteCode,
  useWishlist
} from '~/composables';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useUser } from '~/modules/customer/composables/useUser';
import userGetters from '~/modules/customer/getters/userGetters';
import type { CategoryTree, ProductInterface } from '~/modules/GraphQL/types';
import Header from '~/components/Header/Header.vue';
import { useTopBar } from './TopBar/useTopBar';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import SvgImage from "~/components/General/SvgImage.vue";

export default defineComponent({
  components: {
    SvgImage,
    HeaderNavigation,
    Header,
    SfOverlay,
    SfButton,
    SfBadge,
    SfIcon,
    SfLink,
    CurrencySelector: () => import('~/components/CurrencySelector.vue'),
    StoreSwitcher: () => import('~/components/StoreSwitcher.vue'),
    SearchBar: () => import('~/components/Header/SearchBar/SearchBar.vue'),
    SearchResults: () => import(
      /* webpackPrefetch: true */ '~/components/Header/SearchBar/SearchResults.vue'
      ),
  },
  setup(_, { refs }) {
    const { app, route, localePath } = useContext();
    const router = useRouter();
    const {
      toggleCartSidebar,
      toggleMobileMenu,
      toggleDesktopMenu,
      isDesktopMenuOpen,
      isMobileMenuOpen
    } = useUiState();
    const { setTermForUrl, getCatLink } = useUiHelpers();
    const { isAuthenticated } = useUser();
    const { user, load: loadUser } = useUser();
    const { loadTotalQty: loadCartTotalQty, cart } = useCart();
    const { loadItemsCount: loadWishlistItemsCount } = useWishlist();
    const { categories: categoryList, load: categoriesListLoad } = useCategory();
    const { isMe } = useWebsiteCode();
    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();

    const isSticky = ref(true);
    const isHovered = ref(false);

    const isSearchOpen = ref(false);
    const isShowSearchResult = ref(false);
    const productSearchResults = ref<ProductInterface[] | null>(null);
    const categorySearchResults = ref(null);
    const searchTerm = ref(null);
    const searchTotalResults = ref(0);

    const wishlistStore = useWishlistStore();
    const wishlistItemsQty = computed(() => wishlistStore.wishlist?.items_count ?? 0);

    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);

    const categoryTree = ref<CategoryTree[]>([]);

    useFetch(async () => {
      if (user.value === null) {
        await loadUser();
      }
    });

    const userFirstName = computed(() => user.value
      ? user.value.firstname
      : userGetters.getFirstName(user.value));

    useFetch(async () => {
      await categoriesListLoad({ pageSize: 99 });
      categoryTree.value = categoryList.value?.[0]?.children || [];
    });

    onMounted(async () => {
      await loadCartTotalQty();
      await loadWishlistItemsCount();

      const observer = new IntersectionObserver(() => isSticky.value = !isSticky.value,{ threshold: [1] });
      observer.observe(refs.headerTopWrapper as Element);
    });

    const needTransparentHeaderPages = computed(() => (
      ['home', 'sleep-system', 'yacht', 'couture', 'bedding-in-dubai'].some(term => route.value.name.includes(term))
    ));

    const isHeaderTransparent = computed(() => (
      needTransparentHeaderPages.value &&
        !isSticky.value &&
        !isDesktopMenuOpen.value &&
        !isMobileMenuOpen.value &&
        !isSearchOpen.value
    ));

    const clearSearch = () => {
      isSearchOpen.value = false;
      isShowSearchResult.value = false;
      searchTerm.value = null;
      productSearchResults.value = null;
      categorySearchResults.value = null;
      if (document) document.body.classList.remove('no-scroll');
      setTimeout(() => isHovered.value = false, 10);
    };

    const handleSearchOpen = () => {
      if (isMobileMenuOpen.value) toggleMobileMenu();
      isSearchOpen.value = true;
    }

    const handleAccountClick = async () => {
      if (isDesktopMenuOpen.value) {
        toggleDesktopMenu();
      }
      if (isAuthenticated.value) {
        await router.push(localePath('customer-my-wishlist'))
      } else {
        await router.push(localePath('customer-login-register'));
      }
    }

    const isCustomMadePage = computed(() => route.value.path.includes('/couture'));

    return {
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      categoryTree,
      getCatLink,
      isAuthenticated,
      isHovered,
      isHeaderTransparent,
      isSearchOpen,
      isSticky,
      isShowSearchResult,
      isDesktopMenuOpen,
      isMobileMenuOpen,
      productSearchResults,
      categorySearchResults,
      searchTerm,
      searchTotalResults,
      setTermForUrl,
      toggleCartSidebar,
      toggleMobileMenu,
      toggleDesktopMenu,
      wishlistHasProducts,
      wishlistItemsQty,
      hasCurrencyToSelect,
      hasStoresToSelect,
      userFirstName,
      clearSearch,
      isMe,
      isCustomMadePage,
      handleSearchOpen,
      handleAccountClick,
    };
  },
});
