import { defineStore } from 'pinia';
import { CountrySelectorList } from '~/modules/GraphQL/types';

interface CountriesState {
  countriesList: CountrySelectorList | null
}

export const useCountriesStore = defineStore('countries', {
  state: (): CountriesState => ({
    countriesList: null
  }),
  actions: {
    async load() {
      const { data }: { data: { countrySelector?: CountrySelectorList } } = await this.$nuxt.app.$vsf.$magento.api.getCountries();
      this.countriesList = data?.countrySelector ?? null;
    },
  }
});
