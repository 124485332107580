<template>
  <div
    class="sf-header"
    :class="{
      'is-sticky': sticky,
      'is-hidden': hidden,
      'is-desktop-menu-open': isDesktopMenuOpen
  }">
    <div class="sf-header__wrapper">
      <div ref="header" class="sf-header__header">
        <div class="sf-header__container">
          <div class="sf-header__container-bottom grid">
            <div class="sf-header__container-bottom-left col-m-5">
              <SfButton
                class="sf-header__menu-switcher sf-button--pure desktop-only"
                aria-label="menu switcher"
                :style="{ 'pointer-events' : (isDesktopMenuOpen ? 'none' : 'initial') }"
                @click="toggleDesktopMenu"
              >
                <SvgImage
                  class="sf-header__menu-switcher-icon"
                  :icon="isDesktopMenuOpen ? 'header_menu_closer' : 'header_menu_opener'"
                  :color="isHeaderTransparent ? 'var(--togas-white-color)' : 'var(--togas-black-color)'"
                />
              </SfButton>
              <SfButton
                class="sf-header__action-search sf-button--pure sf-header__action desktop-only"
                data-testid="searchIcon"
                aria-label="Search"
                @click="$emit('is-search-open', true)"
              >
                <SvgImage
                  class="sf-header__icon"
                  icon="header_search"
                  :color="isHeaderTransparent ? 'var(--togas-white-color)' : 'var(--togas-black-color)'"
                />
              </SfButton>
              <StoreSwitcher
                class="desktop-only"
              />
              <CurrencySelector
                class="desktop-only"
                v-if="hasCurrencyToSelect"
              />
              <slot name="me-custom-made-address" />
              <slot name="search" v-bind="{ searchValue, searchPlaceholder }">
                <SfSearchBar
                  :value="searchValue"
                  :placeholder="searchPlaceholder"
                  aria-label="Search"
                  class="sf-header__search"
                  @input="$emit('change:search', $event)"
                  @keyup.enter="$emit('enter:search', $event)"
                />
              </slot>
            </div>

            <div class="sf-header__mobile-container col-4 mobile-only">
              <MobileNavigationItem
                class="top-navigation-menu"
                @click="toggleMobileMenu"
              >
                <template #icon>
                  <SvgImage
                    :icon="isMobileMenuOpen ? 'header_menu_closer' : 'header_menu_opener'"
                    :color="isHeaderTransparent ? 'var(--togas-white-color)' : 'var(--togas-black-color)'"
                    height="24"
                    width="24"
                  />
                </template>
              </MobileNavigationItem>
              <MobileNavigationItem
                class="top-navigation-menu"
                @click="$emit('is-search-open', true)"
              >
                <template #icon>
                  <SvgImage
                    icon="header_search"
                    :color="isHeaderTransparent ? 'var(--togas-white-color)' : 'var(--togas-black-color)'"
                    height="20"
                    width="20"
                  />
                </template>
              </MobileNavigationItem>
            </div>

            <div class="sf-header__logo-wrapper col-4 col-m-2">
              <slot name="logo" />
            </div>

            <div class="sf-header__mobile-container --right col-4 mobile-only">
              <MobileNavigationItem
                class="top-navigation-wishlist mobile-wishlist-icon"
                @click="$router.push(localePath('customer-my-wishlist'))"
              >
                <template #icon>
                  <SvgImage
                    icon="header_wishlist"
                    :color="isHeaderTransparent ? 'var(--togas-white-color)' : 'var(--togas-black-color)'"
                    height="20"
                    width="20"
                  />
                  <SfBadge
                    v-if="wishlistHasProducts"
                    class="sf-badge--number wishlist-badge"
                  >
                    {{ wishlistItemsQty }}
                  </SfBadge>
                </template>
              </MobileNavigationItem>
              <MobileNavigationItem
                class="top-navigation-cart mobile-cart-icon"
                @click="$router.push(localePath('cart'))"
              >
                <template #icon>
                  <SvgImage
                    icon="header_cart"
                    :color="isHeaderTransparent ? 'var(--togas-white-color)' : 'var(--togas-black-color)'"
                    height="20"
                    width="20"
                  />
                  <SfBadge
                    v-if="cartTotalItems"
                    class="sf-badge--number cart-badge"
                  >
                    {{ cartTotalItems }}
                  </SfBadge>
                </template>
              </MobileNavigationItem>
            </div>

            <div class="sf-header__actions col-12 col-m-5">
              <slot name="me-custom-made-wa" />
               <div
                v-if="isMe"
                class="additional">
                <a
                  class="link"
                  aria-label="website link"
                  href="https://custom.togas.com/?_gl=1*w1c9k1*_gcl_au*OTgzMjQ4ODIzLjE3Mjg0NjQ3MTY"
                  target="_blank"
                  :style="isHeaderTransparent
                    ? 'color: var(--togas-white-color)'
                    : 'color: var(--togas-grey-color)'"
                  v-text="'Atelier Couture'"
                />
              </div>

              <div
                v-if="storePhone && isAu"
                class="additional"
              >
                <a
                  class="link"
                  aria-label="phone link"
                  :href="`tel:+${storePhone.replace(/[^0-9]/g, '')}`"
                  :style="isHeaderTransparent
                    ? 'color: var(--togas-white-color)'
                    : 'color: var(--togas-grey-color)'"
                  v-text="storePhone"
                />
              </div>
              <slot name="header-icons" />
            </div>
          </div>
        </div>
        <div
          class="sf-header__navigation"
          v-if="isDesktopMenuOpen"
          v-click-outside="toggleDesktopMenu"
        >
          <slot name="navigation" />
        </div>
      </div>
    </div>
    <CustomMadeNavigation
      v-if="isMe"
      :is-header-transparent="isHeaderTransparent"
    />
    <MobileCategorySidebar
      :category-tree="categoryTree"
      v-if="isMobileMenuOpen"
    />
  </div>
</template>
<script>
import Vue from 'vue';
import SfHeaderNavigationItem
  from '~/components/organisms/SfHeader/_internal/SfHeaderNavigationItem.vue';
import SfHeaderNavigation from '~/components/organisms/SfHeader/_internal/SfHeaderNavigation.vue';

Vue.component('SfHeaderNavigation', SfHeaderNavigation);
Vue.component('SfHeaderNavigationItem', SfHeaderNavigationItem);
import { isClient } from '~/utilities/helpers';
import { clickOutside } from '~/utilities/directives';

import {
  SfImage,
  SfSearchBar,
  SfButton,
  SfLink,
  SfBadge,
  SvgImage,
} from '~/components';

import { useTopBar } from '~/components/TopBar/useTopBar';
import { useUiState, useUser, useCart, useWebsiteCode } from '~/composables';
import { useConfigStore } from '~/stores/config';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';
import { useCountriesStore } from '~/modules/countries/stores/countries';
import { computed, useContext, useRouter, useFetch } from '@nuxtjs/composition-api';

export default {
  name: 'Header',
  directives: {clickOutside},
  components: {
    SfImage,
    SfSearchBar,
    SfButton,
    SfLink,
    SfBadge,
    SvgImage,
    MobileCategorySidebar: () => import('~/modules/catalog/category/components/sidebar/MobileCategorySidebar/MobileCategorySidebar.vue'),
    MobileNavigationItem: () => import('~/components/organisms/MobileNavigation/MobileNavigationItem.vue'),
    CurrencySelector: () => import('~/components/CurrencySelector.vue'),
    StoreSwitcher: () => import('~/components/StoreSwitcher.vue'),
    CustomMadeNavigation: () => import('~/components/Header/CustomMadeNavigation.vue'),
  },
  emit: ['is-search-open'],
  setup() {
    const {
      hasCurrencyToSelect,
      hasStoresToSelect
    } = useTopBar();

    const {
      toggleCartSidebar,
      toggleWishlistSidebar,
      toggleDesktopMenu,
      toggleMobileMenu,
      isDesktopMenuOpen,
      isMobileMenuOpen,
    } = useUiState();

    const { isInt, isMe, isAu } = useWebsiteCode();

    const {isAuthenticated} = useUser();
    const { app, route } = useContext();
    const { cart } = useCart();
    const router = useRouter();
    const { categories, load: loadCategories } = useCategoryStore();
    const { countriesList, load: loadCountriesList } = useCountriesStore();

    useFetch(async () => {
      if (categories === null) {
        await loadCategories();
      }
      if (countriesList === null) {
        await loadCountriesList();
      }
    });

    const storePhone = computed(() => useConfigStore().storeConfig.store_information?.phone);

    const handleHomeClick = async () => {
      const homePath = app.localeRoute({name: 'home'});
      await router.push(homePath);
      if(isMobileMenuOpen.value) {
        toggleMobileMenu();
      }
    };

    const isCustomMadePage = computed(() => route.value.path.includes('/couture'));

    return {
      hasCurrencyToSelect,
      hasStoresToSelect,
      isAuthenticated,
      toggleDesktopMenu,
      isDesktopMenuOpen,
      isMobileMenuOpen,
      toggleWishlistSidebar,
      toggleCartSidebar,
      toggleMobileMenu,
      handleHomeClick,
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      storePhone,
      isMe,
      isInt,
      isAu,
      isCustomMadePage,
    };
  },
  props: {
    categoryTree: {
      default: () => [],
    },
    logo: {
      type: [String, Object],
      default: '',
    },
    logoHeight: {
      type: [Number, String],
      default: 35,
    },
    logoWidth: {
      type: [Number, String],
      default: 34,
    },
    title: {
      type: String,
      default: '',
    },
    cartIcon: {
      type: [String, Boolean, Array],
      default: 'empty_cart',
    },
    wishlistIcon: {
      type: [String, Boolean, Array],
      default: 'heart',
    },
    accountIcon: {
      type: [String, Boolean, Array],
      default: 'profile',
    },
    activeIcon: {
      type: String,
      default: '',
      validator(value) {
        return ['', 'account', 'wishlist', 'cart'].includes(value);
      },
    },
    searchPlaceholder: {
      type: String,
      default: 'Search',
    },
    searchValue: {
      type: String,
      default: '',
    },
    wishlistItemsQty: {
      type: [String, Number],
      default: '0',
    },
    cartItemsQty: {
      type: [String, Number],
      default: '0',
    },
    isSticky: {
      type: Boolean,
      default: true,
    },
    isNavVisible: {
      type: Boolean,
      default: false,
    },
    isHeaderTransparent: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      hidden: false,
      sticky: false,
      scrollDirection: null,
      lastScrollPosition: 0,
      animationStart: null,
      animationLong: null,
      animationDuration: 300,
    };
  },
  computed: {
    cartHasProducts() {
      return parseInt(this.cartItemsQty, 10) > 0;
    },
    wishlistHasProducts() {
      return parseInt(this.wishlistItemsQty, 10) > 0;
    },
  },
  watch: {
    scrollDirection: {
      handler() {
        if(!isClient) return;
        window.cancelAnimationFrame(this.animationLong);
        this.animationLong = null;
        this.animationStart = null;
        this.animationLong = window.requestAnimationFrame(
          this.animationHandler
        );
      },
    },
    isSticky: {
      handler(isSticky) {
        if(!isClient) return;
        this.sticky = isSticky;
      },
      immediate: true,
    },
  },
  mounted() {
    if(this.isSticky) {
      window.addEventListener('scroll', this.scrollHandler, {passive: true});
    }
  },
  beforeDestroy() {
    if(this.isSticky) {
      window.removeEventListener('scroll', this.scrollHandler, {
        passive: true,
      });
    }
  },
  methods: {
    animationHandler(timestamp) {
      if(!this.animationStart) this.animationStart = timestamp;
      const progress = timestamp - this.animationStart;
      if(progress < this.animationDuration) {
        this.animationLong = window.requestAnimationFrame(
          this.animationHandler
        );
        return;
      }
      this.hidden = this.scrollDirection === 'down';
    },
    scrollHandler() {
      if(!isClient) return;
      const currentScrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      if(!!this.refs) {
        if(currentScrollPosition >= this.$refs.header.offsetHeight) {
          this.scrollDirection =
            currentScrollPosition < this.lastScrollPosition ? 'up' : 'down';
        }
      }
      this.lastScrollPosition = currentScrollPosition;
    },
  },
};
</script>
<style lang="scss">
@import "~@/styles/components/organisms/SfHeader.scss";
</style>
