var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"subscribe"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.resetValidation),expression:"resetValidation"}],staticClass:"subscribe__container"},[_c('SfHeading',{staticClass:"subscribe__title sf-heading--left",attrs:{"title":_vm.$tc('Subscribe to receive 10% OFF'),"level":2,"styleLevel":1}}),_vm._v(" "),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{staticClass:"subscribe__form",attrs:{"id":"subscribe-form"},on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.submitForm(reset, _vm.form.email));}}},[_c('div',{staticClass:"subscribe__input-wrapper"},[_c('ValidationProvider',{staticClass:"subscribe__input-container",attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfInput',{staticClass:"subscribe__input",attrs:{"id":"subscribe-form-email","name":"subscribe-email","placeholder":_vm.$t('Enter your email address'),"valid":!errors[0],"error-message":_vm.$t(errors[0])},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.email"}})]}}],null,true)}),_vm._v(" "),_c('SfButton',{staticClass:"subscribe__button",attrs:{"type":"submit","aria-label":"subscribe to newsletter"}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 20 20","fill":"#FFFFFF"}},[_c('g',{attrs:{"clip-path":"url(#clip0_4324_3799)"}},[_c('path',{attrs:{"d":"M5.58406 0.268337C5.50976 0.190676 5.40818 0.162614 5.27981 0.296795C5.23207 0.340433 5.09891 0.505745 5.02584 0.59244C4.90195 0.73945 4.91909 0.81165 5.08943 0.989701C5.25976 1.16775 13.7289 9.19429 13.9196 9.3937C14.1104 9.59312 14.0469 9.75459 13.9196 9.88758C13.7924 10.0206 5.29312 18.2081 5.09413 18.4161C4.89515 18.6241 4.91447 18.7599 5.02779 18.8784L5.29312 19.1557C5.38157 19.2482 5.54464 19.1991 5.67454 19.0633C5.80444 18.9275 15.0635 10.0407 15.21 9.88758C15.3564 9.73446 15.3205 9.61022 15.21 9.49466C15.0994 9.3791 5.65835 0.345999 5.58406 0.268337Z"}})]),_vm._v(" "),_c('defs',[_c('clipPath',{attrs:{"id":"clip0_4324_3799"}},[_c('rect',{attrs:{"width":"19","height":"19","fill":"white","transform":"translate(0.625 0.203125)"}})])])])])],1),_vm._v(" "),_c('ValidationProvider',{staticClass:"subscribe__checkbox-container",attrs:{"rules":{ required: { allowFalse: false } }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SfCheckbox',{staticClass:"subscribe-checkbox",attrs:{"id":"subscribe-form-checkbox","name":"agreement","valid":!errors[0],"error-message":_vm.$t(errors[0]),"label":_vm.$t('By opting in I agree to Togas`s terms and conditions and understand I can opt out at any time')},model:{value:(_vm.form.agreement),callback:function ($$v) {_vm.$set(_vm.form, "agreement", $$v)},expression:"form.agreement"}})]}}],null,true)}),_vm._v(" "),(_vm.isRecaptchaEnabled)?_c('recaptcha'):_vm._e()],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }